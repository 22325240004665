import { ReactNode } from 'react';

import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import FilterVintageTwoToneIcon from '@mui/icons-material/FilterVintageTwoTone';
import HowToVoteTwoToneIcon from '@mui/icons-material/HowToVoteTwoTone';
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import CategoryTwoToneIcon from '@mui/icons-material/CategoryTwoTone';
import RateReviewTwoToneIcon from '@mui/icons-material/RateReviewTwoTone';
import { BookOnlineTwoTone,  CalendarMonthTwoTone,  Details } from '@mui/icons-material';
import { format } from 'date-fns';
// import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
// import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
// import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
// import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
// import BeachAccessTwoToneIcon from '@mui/icons-material/BeachAccessTwoTone';
// import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
// import LocalPharmacyTwoToneIcon from '@mui/icons-material/LocalPharmacyTwoTone';
// import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
// import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
// import TrafficTwoToneIcon from '@mui/icons-material/TrafficTwoTone';
// import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] =
  localStorage.paymentAdmin === "yes" ?
    [
      {
        heading: '',
        items: [
          {
            name: 'Overview',
            link: '/dashboard/overview',
            icon: DesignServicesTwoToneIcon
          }
        ]
      },
      {
        heading: 'Dashboards',
        items: [
          {
            name: 'Store',
            link: '/dashboard/stores',
            icon: BrightnessLowTwoToneIcon
          },
          {
            name: 'Brand',
            link: '/dashboard/brand',
            icon: FilterVintageTwoToneIcon
          },
          {
            name: 'Categories',
            link: '/dashboard/categories',
            icon: CategoryTwoToneIcon
          },
          {
            name: 'Amenities',
            link: '/dashboard/amenities',
            icon: HowToVoteTwoToneIcon
          },
          {
            name: 'Offers',
            icon: LocalOfferTwoToneIcon,
            items: [
              {
                name: 'Offer Requests',
                link: '/dashboard/offers',
                icon: LocalOfferTwoToneIcon
              },
              {
                name: 'Offers',
                link: '/dashboard/offerList',
                icon: LocalOfferTwoToneIcon
              }
            ]
          },
          {
            name: 'Organizers',
            icon: RateReviewTwoToneIcon,
            items: [
              {
                name: 'Organizers List',
                link: '/dashboard/organizer',
                icon: RateReviewTwoToneIcon,
              },
            ]
          },
          {
            name: 'Events',
            icon: RateReviewTwoToneIcon,
            items: [
              {
                name: 'Event Request',
                link: '/dashboard/eventrequest',
                icon: RateReviewTwoToneIcon
              },
              {
                name: 'Event List',
                link: '/dashboard/event',
                icon: RateReviewTwoToneIcon,
              },
              {
                name: 'Event Expired',
                link: '/dashboard/eventexpired',
                icon: BookOnlineTwoTone,
              },

              {
                name: 'Event Categories',
                link: '/dashboard/eventcategories',
                icon: CategoryTwoToneIcon,
              },
              {
                name: 'Event Booking',
                link: '/dashboard/eventbooking',
                icon: BookOnlineTwoTone,
              },
              {
                name: 'Event Dates',
                link: '/dashboard/eventdates',
                icon: CalendarMonthTwoTone,
              },
            ]
          },
          {
            name: 'Payments',
            icon: RateReviewTwoToneIcon,
            items: [
              {
                name: 'Shop Payment',
                link: '/dashboard/ShopPayment',
                icon: RateReviewTwoToneIcon,
              },
              {
                name: 'User Payment',
                link: '/dashboard/userPayment',
                icon: RateReviewTwoToneIcon,
              },
            ]
          },
          {
            name: 'Feedback',
            link: '/dashboard/feedback',
            icon: RateReviewTwoToneIcon
          },
          {
            name: 'Referal',
            link: '/dashboard/referal',
            icon: RateReviewTwoToneIcon
          },
          {
            name: 'Notification',
            link: '/dashboard/notification',
            icon: RateReviewTwoToneIcon
          },
          {
            name:'Sellement Logs',
            link:'/dashboard/settlementLogs',
            icon:RateReviewTwoToneIcon
          }
          // {
          //   name: 'Messenger',
          //   icon: MmsTwoToneIcon,
          //   link: '/dashboard/messenger'
          // },
        ]
      },
      // {
      //   heading: 'Management',
      //   items: [
      //     {
      //       name: 'Transactions',
      //       icon: TableChartTwoToneIcon,
      //       link: '/management/transactions'
      //     },
      //     {
      //       name: 'User Profile',
      //       icon: AccountCircleTwoToneIcon,
      //       link: '/management/profile',
      //       items: [
      //         {
      //           name: 'Profile Details',
      //           link: '/management/profile/details'
      //         },
      //         {
      //           name: 'User Settings',
      //           link: '/management/profile/settings'
      //         }
      //       ]
      //     }
      //   ]
      // },
      // {
      //   heading: 'Components',
      //   items: [
      //     {
      //       name: 'Buttons',
      //       icon: BallotTwoToneIcon,
      //       link: '/components/buttons'
      //     },
      //     {
      //       name: 'Modals',
      //       icon: BeachAccessTwoToneIcon,
      //       link: '/components/modals'
      //     },
      //     {
      //       name: 'Accordions',
      //       icon: EmojiEventsTwoToneIcon,
      //       link: '/components/accordions'
      //     },
      //     {
      //       name: 'Tabs',
      //       icon: FilterVintageTwoToneIcon,
      //       link: '/components/tabs'
      //     },
      //     {
      //       name: 'Badges',
      //       icon: HowToVoteTwoToneIcon,
      //       link: '/components/badges'
      //     },
      //     {
      //       name: 'Tooltips',
      //       icon: LocalPharmacyTwoToneIcon,
      //       link: '/components/tooltips'
      //     },
      //     {
      //       name: 'Avatars',
      //       icon: RedeemTwoToneIcon,
      //       link: '/components/avatars'
      //     },
      //     {
      //       name: 'Cards',
      //       icon: SettingsTwoToneIcon,
      //       link: '/components/cards'
      //     },
      //     {
      //       name: 'Forms',
      //       icon: TrafficTwoToneIcon,
      //       link: '/components/forms'
      //     },
      //   ]
      // },
      // {
      //   heading: 'Extra Pages',
      //   items: [
      //     {
      //       name: 'Status',
      //       icon: VerifiedUserTwoToneIcon,
      //       link: '/status',
      //       items: [
      //         {
      //           name: 'Error 404',
      //           link: '/status/404'
      //         },
      //         {
      //           name: 'Error 500',
      //           link: '/status/500'
      //         },
      //         {
      //           name: 'Maintenance',
      //           link: '/status/maintenance'
      //         },
      //         {
      //           name: 'Coming Soon',
      //           link: '/status/coming-soon'
      //         }
      //       ]
      //     }
      //   ]
      // }
    ] :
    [
      {
        heading: '',
        items: [
          {
            name: 'Overview',
            link: '/dashboard/overview',
            icon: DesignServicesTwoToneIcon
          }
        ]
      },
      {
        heading: 'Dashboards',
        items: [
          {
            name: 'Store',
            link: '/dashboard/stores',
            icon: BrightnessLowTwoToneIcon
          },
          {
            name: 'Brand',
            link: '/dashboard/brand',
            icon: FilterVintageTwoToneIcon
          },
          {
            name: 'Categories',
            link: '/dashboard/categories',
            icon: CategoryTwoToneIcon
          },
          {
            name: 'Amenities',
            link: '/dashboard/amenities',
            icon: HowToVoteTwoToneIcon
          },
          {
            name: 'Offers',
            icon: LocalOfferTwoToneIcon,
            items: [
              {
                name: 'Offer Requests',
                link: '/dashboard/offers',
                icon: LocalOfferTwoToneIcon
              },
              {
                name: 'Offers',
                link: '/dashboard/offerList',
                icon: LocalOfferTwoToneIcon
              }
            ]
          },          {
            name: 'Organizers',
            icon: RateReviewTwoToneIcon,
            items: [
              {
                name: 'Organizers List',
                link: '/dashboard/organizer',
                icon: RateReviewTwoToneIcon,
              },
            ]
          },
          {
            name: 'Events',
            icon: RateReviewTwoToneIcon,
            items: [
              {
                name: 'Event Request',
                link: '/dashboard/eventrequest',
                icon: RateReviewTwoToneIcon
              },
              {
                name: 'Event List',
                link: '/dashboard/event',
                icon: RateReviewTwoToneIcon,
              },
              {
                name: 'Event Expired',
                link: '/dashboard/eventexpired',
                icon: BookOnlineTwoTone,
              },

              {
                name: 'Event Categories',
                link: '/dashboard/eventcategories',
                icon: CategoryTwoToneIcon,
              },
              {
                name: 'Event Booking',
                link: '/dashboard/eventbooking',
                icon: BookOnlineTwoTone,
              },
              {
                name: 'Event Dates',
                link: '/dashboard/eventdates',
                icon: CalendarMonthTwoTone,
              },


            ]
          },
          {
            name: 'Feedback',
            link: '/dashboard/feedback',
            icon: RateReviewTwoToneIcon
          },
          {
            name: 'Referal',
            link: '/dashboard/referal',
            icon: RateReviewTwoToneIcon
          },
          {
            name: 'Notification',
            link: '/dashboard/notification',
            icon: RateReviewTwoToneIcon
          },


          // {
          //   name: 'Messenger',
          //   icon: MmsTwoToneIcon,
          //   link: '/dashboard/messenger'
          // },
        ]
      },
      // {
      //   heading: 'Management',
      //   items: [
      //     {
      //       name: 'Transactions',
      //       icon: TableChartTwoToneIcon,
      //       link: '/management/transactions'
      //     },
      //     {
      //       name: 'User Profile',
      //       icon: AccountCircleTwoToneIcon,
      //       link: '/management/profile',
      //       items: [
      //         {
      //           name: 'Profile Details',
      //           link: '/management/profile/details'
      //         },
      //         {
      //           name: 'User Settings',
      //           link: '/management/profile/settings'
      //         }
      //       ]
      //     }
      //   ]
      // },
      // {
      //   heading: 'Components',
      //   items: [
      //     {
      //       name: 'Buttons',
      //       icon: BallotTwoToneIcon,
      //       link: '/components/buttons'
      //     },
      //     {
      //       name: 'Modals',
      //       icon: BeachAccessTwoToneIcon,
      //       link: '/components/modals'
      //     },
      //     {
      //       name: 'Accordions',
      //       icon: EmojiEventsTwoToneIcon,
      //       link: '/components/accordions'
      //     },
      //     {
      //       name: 'Tabs',
      //       icon: FilterVintageTwoToneIcon,
      //       link: '/components/tabs'
      //     },
      //     {
      //       name: 'Badges',
      //       icon: HowToVoteTwoToneIcon,
      //       link: '/components/badges'
      //     },
      //     {
      //       name: 'Tooltips',
      //       icon: LocalPharmacyTwoToneIcon,
      //       link: '/components/tooltips'
      //     },
      //     {
      //       name: 'Avatars',
      //       icon: RedeemTwoToneIcon,
      //       link: '/components/avatars'
      //     },
      //     {
      //       name: 'Cards',
      //       icon: SettingsTwoToneIcon,
      //       link: '/components/cards'
      //     },
      //     {
      //       name: 'Forms',
      //       icon: TrafficTwoToneIcon,
      //       link: '/components/forms'
      //     },
      //   ]
      // },
      // {
      //   heading: 'Extra Pages',
      //   items: [
      //     {
      //       name: 'Status',
      //       icon: VerifiedUserTwoToneIcon,
      //       link: '/status',
      //       items: [
      //         {
      //           name: 'Error 404',
      //           link: '/status/404'
      //         },
      //         {
      //           name: 'Error 500',
      //           link: '/status/500'
      //         },
      //         {
      //           name: 'Maintenance',
      //           link: '/status/maintenance'
      //         },
      //         {
      //           name: 'Coming Soon',
      //           link: '/status/coming-soon'
      //         }
      //       ]
      //     }
      //   ]
      // }
    ];


export default menuItems;
