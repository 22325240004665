import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import Login from "src/content/login";
import SidebarLayout from "src/layouts/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";

import SuspenseLoader from "src/components/SuspenseLoader";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Home = Loader(lazy(() => import("src/content/home")));

// Applications
const UserProfile = Loader(
  lazy(() => import("src/content/applications/Users/profile"))
);
const UserSettings = Loader(
  lazy(() => import("src/content/applications/Users/settings"))
);
const AppStore = Loader(
  lazy(() => import("src/content/applications/Users/AppStore"))
);

// Status
const Status404 = Loader(
  lazy(() => import("src/content/pages/Status/Status404"))
);
const Status500 = Loader(
  lazy(() => import("src/content/pages/Status/Status500"))
);
// const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
// const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));

// Dashboards
const Brand = Loader(lazy(() => import("src/content/brand")));
const Stores = Loader(lazy(() => import("src/content/stores")));
const Categories = Loader(lazy(() => import("src/content/categories")));
const Amenities = Loader(lazy(() => import("src/content/amenity")));
const Offers = Loader(lazy(() => import("src/content/offers")));
const OfferList = Loader(lazy(() => import("src/content/offerList")));
const FeedBack = Loader(lazy(() => import("src/content/feedback")));
const Referal = Loader(lazy(() => import("src/content/referal")));
const Notification = Loader(lazy(() => import("src/content/notification")));
const EventCategories = Loader(lazy(() => import("src/content/event_Categories")));
const EventRequest = Loader(lazy(() => import("src/content/eventRequest")));
const EventExpired = Loader(lazy(() => import("src/content/event_Expired")));
const EventBookings = Loader(lazy(() => import("src/content/event_Booking")));
const EventDates = Loader(lazy(() => import("src/content/event_Dates")));
const Event = Loader(lazy(() => import("src/content/event")));
const Organiser = Loader(lazy(() => import("src/content/organizer")));
const ShopPayment = Loader(lazy(() => import("src/content/Shop-Payment")));
const UserPayment = Loader(lazy(() => import("src/content/User-Payment")));
const SettlementLogs = Loader(lazy(() => import("src/content/settlementLogs")));
const routes =
  localStorage.paymentAdmin === "yes"
    ? (isLoggedIn) => [
        {
          path: "/",
          element: isLoggedIn ? (
            <Navigate to="/dashboard/overview" replace />
          ) : (
            <Login />
          ),
        },
        {
          path: "dashboard",
          element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/" />,
          children: [
            {
              path: "/",
              element: <Navigate to="/dashboard/overview" replace />,
            },
            {
              path: "overview",
              element: <Home />,
            },
            {
              path: "stores",
              element: <Stores />,
            },
            {
              path: "brand",
              element: <Brand />,
            },
            {
              path: "categories",
              element: <Categories />,
            },
            {
              path: "amenities",
              element: <Amenities />,
            },
            {
              path: "offers",
              element: <Offers />,
            },
            {
              path: "offerList",
              element: <OfferList />,
            },
            {
              path: "feedback",
              element: <FeedBack />,
            },
            {
              path: "referal",
              element: <Referal />,
            },
            {
              path: "notification",
              element: <Notification />,
            },
            {
              path: "eventrequest",
              element: <EventRequest />,
            },
            {
              path: "eventcategories",
              element: <EventCategories />,
            },
            {
              path: "eventexpired",
              element: <EventExpired />,
            },

            {
              path: "eventbooking",
              element: <EventBookings />,
            },
            {
              path: "event",
              element: <Event />,
            },
            {
              path: "eventdates",
              element: <EventDates />,
            },
            {
              path: "organizer",
              element: <Organiser />,
            },
            {
              path: "ShopPayment",
              element: <ShopPayment />,
            },
            {
              path: "userPayment",
              element: <UserPayment />,
            },
            {
              path: "settlementLogs",
              element: <SettlementLogs />,
            },
          ],
        },
        {
          path: "management",
          element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/" />,
          children: [
            {
              path: "/",
              element: <Navigate to="/management/transactions" replace />,
            },
            {
              path: "profile",
              children: [
                {
                  path: "/",
                  element: <Navigate to="details" replace />,
                },
                {
                  path: "details",
                  element: <UserProfile />,
                },
                {
                  path: "settings",
                  element: <UserSettings />,
                },
                {
                  path: "AppStore",
                  element: <AppStore />,
                },
              ],
            },
          ],
        },
        {
          path: "*",
          element: <BaseLayout />,
          children: [
            {
              path: "/",
              element: <Navigate to="/dashboard" replace />,
            },
            {
              path: "status",
              children: [
                {
                  path: "/",
                  element: <Navigate to="404" replace />,
                },
                {
                  path: "404",
                  element: <Status404 />,
                },
                {
                  path: "500",
                  element: <Status500 />,
                },
                // {
                //   path: 'maintenance',
                //   element: <StatusMaintenance />
                // },
                // {
                //   path: 'coming-soon',
                //   element: <StatusComingSoon />
                // },
              ],
            },
            {
              path: "*",
              element: <Status404 />,
            },
          ],
        },
      ]
    : (isLoggedIn) => [
        {
          path: "/",
          element: isLoggedIn ? (
            <Navigate to="/dashboard/overview" replace />
          ) : (
            <Login />
          ),
        },
        {
          path: "dashboard",
          element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/" />,
          children: [
            {
              path: "/",
              element: <Navigate to="/dashboard/overview" replace />,
            },
            {
              path: "overview",
              element: <Home />,
            },
            {
              path: "stores",
              element: <Stores />,
            },
            {
              path: "brand",
              element: <Brand />,
            },
            {
              path: "categories",
              element: <Categories />,
            },
            {
              path: "amenities",
              element: <Amenities />,
            },
            {
              path: "offers",
              element: <Offers />,
            },
            {
              path: "offerList",
              element: <OfferList />,
            },
            {
              path: "feedback",
              element: <FeedBack />,
            },
            {
              path: "referal",
              element: <Referal />,
            },
            {
              path: "notification",
              element: <Notification />,
            },
            {
              path: "eventrequest",
              element: <EventRequest />,
            },
            {
              path: "eventbooking",
              element: <EventBookings />,
            },
            {
              path: "event",
              element: <Event />,
            },
            {
              path: "eventcategories",
              element: <EventCategories />,
            },
            {
              path: "eventexpired",
              element: <EventExpired />,
            },
            {
              path: "eventdates",
              element: <EventDates />,
            },
            {
              path: "organizer",
              element: <Organiser />,
            },
          ],
        },
        {
          path: "management",
          element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/" />,
          children: [
            {
              path: "/",
              element: <Navigate to="/management/transactions" replace />,
            },
            {
              path: "profile",
              children: [
                {
                  path: "/",
                  element: <Navigate to="details" replace />,
                },
                {
                  path: "details",
                  element: <UserProfile />,
                },
                {
                  path: "settings",
                  element: <UserSettings />,
                },
                {
                  path: "AppStore",
                  element: <AppStore />,
                },
              ],
            },
          ],
        },
        {
          path: "*",
          element: <BaseLayout />,
          children: [
            {
              path: "/",
              element: <Navigate to="/dashboard" replace />,
            },
            {
              path: "status",
              children: [
                {
                  path: "/",
                  element: <Navigate to="404" replace />,
                },
                {
                  path: "404",
                  element: <Status404 />,
                },
                {
                  path: "500",
                  element: <Status500 />,
                },
                // {
                //   path: 'maintenance',
                //   element: <StatusMaintenance />
                // },
                // {
                //   path: 'coming-soon',
                //   element: <StatusComingSoon />
                // },
              ],
            },
            {
              path: "*",
              element: <Status404 />,
            },
          ],
        },
      ];

export default routes;
